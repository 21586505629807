import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'spinner-component',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnInit {
  @Input() message: string = 'Cargando...';
  @Input() imagen_src: string = '/assets/icons/jeds/apple-icon.png'
  constructor() { }

  ngOnInit(): void { }
}
