import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewContainerRef,
  ViewEncapsulation,
} from "@angular/core";
import { Overlay, OverlayRef } from "@angular/cdk/overlay";
import { Subject, takeUntil } from "rxjs";
import { HeaderTextService } from "./header-text.service";
import { Router } from '@angular/router';
import { TitleType } from "./header-text.types";

@Component({
  selector: "header-text",
  templateUrl: "./header-text.component.html",
  encapsulation: ViewEncapsulation.None,
  exportAs: "headerText",
  standalone: true
})
export class HeaderTextComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _headerTextService: HeaderTextService,
    private _overlay: Overlay,
    private _viewContainerRef: ViewContainerRef
  ) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
  }

  getTitulo() {
    let titulo = this._headerTextService.getTitulo();
    if (titulo['custom_title']) {
      return titulo['titulo'];
    }
    return TitleType.find((title) => title.path === titulo)?.text;
  }
  
  getPath() {
    return this._headerTextService.getTitulo() != "/" ? this._headerTextService.getTitulo() : "";
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

}
